/* src/components/Steps.css */
 
.step-icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.step-icon img {
  max-width: 50%;
}

h2, h4 {
  color: #333;
}

.text-uppercase {
  color: #777;
  letter-spacing: 1px;
}

.bkgrdstepsimg{
  background-image: url('https://images.unsplash.com/photo-1674421268449-d68facc81eca?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}