/* src/components/ExpandableSidebar.css */

.expandable-sidebar {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50px; /* Initial width when collapsed */
    transition: width 0.3s ease;
    z-index: 1000;
    background-color: #3c3365; /* Sidebar background color */
    border-radius: 8px 0 0 8px;
  }
  
  .expandable-sidebar.expanded {
    width: 200px; /* Width when expanded */
  }
  
  .toggle-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: #3c3365;
    position: relative;
    color: #fff;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    position: fixed; /* Change to fixed for sticking to the right side */
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s, right 0.3s;
  }

  .expandable-sidebar.expanded .toggle-button {
    right: 30px; /* Adjust based on sidebar width when expanded */
    border-radius: 50%;
  }
  
  .toggle-button:hover {
    background-color: #004494; /* Darker shade on hover */
  }
  
  .sidebar-content {
    padding: 10px;
    margin: 0;
  }
  
  .sidebar-item {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .sidebar-link {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .sidebar-link:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light highlight on hover */
  }
  
  .sidebar-text {
    margin-left: 10px;
    white-space: nowrap; /* Prevent text wrapping */
  }
  