/* src/components/CarCards.css */

.car-card {
    border-radius: 15px;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    width: 100%;
  }
  
  .car-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .car-image-container {
    padding: 15px;
    border-radius: 15px 15px 0 0;
    /* position: relative; */
  }
  
  .car-image {
    transition: transform 0.3s, filter 0.3s;
    max-height: 150px;
    object-fit: contain;
    width: 100%;
  }
  
  .car-card:hover .car-image {
    transform: translateY(-10px);
    filter: drop-shadow(0 0 10px rgba(0, 255, 255, 0.5)); /* Fluorescent light effect */
  }
  
  .btn-primary {
    background-color: #563d7c;
    border: none;
    transition: background-color 0.3s;
  }
  
  .btn-primary:hover {
    background-color: #452c5f;
  }