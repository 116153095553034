/* src/components/BestServices.css */

.best-services-container {
    background-color: #f9f9f9; /* Optional: Light background for the entire section */
  }
  
  .car-image {
    max-width: 100%;
    height: auto;
    object-fit: contain; /* Ensures image fits well within its column */
  }
  
  .icon-container {
    width: 50px;
    height: 50px;
    background-color: #f1f1f1; /* Light background for icons */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon-container img {
    max-width: 60%;
  }
  
  h2, h5 {
    color: #333;
  }
  
  .text-muted {
    color: #777;
  }
  
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  