/* src/components/Navbar.css */

.custom-navbar {
    background-color: #fff;
    border-bottom: 2px solid #f1f1f1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-logo {
    height: 40px; /* Adjust logo size */
  }
  
  .nav-link {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0 15px;
    color: #555 !important;
    transition: color 0.3s;
  }
  
  .nav-link:hover {
    color: #f6564b !important; /* Hover effect color */
  }
  
  .navbar-toggler {
    border: none; /* Cleaner look for the toggler button */
  }
  
  /* src/styles/Header.css */

.btn-call {
  background-color: #0d55ff; /* Green color */
  color: white;
  border-radius: 50px;
  padding: 10px 20px;
  transition: transform 0.3s ease;
  margin-right: 30px;
}

.btn-call:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  background-color: #00ca14; /* Darker green on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.custom-navbar {
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .navbar-logo {
    height: 30px;
    width: 30px;
  }

  .btn-call {
    font-size: 0.9rem;
    padding: 0.4rem 0.6rem;
  }
}

.slide-in {
  opacity: 0;
  transform: translateX(100%); /* Start outside the screen on the right */
  animation: slideInAnimation 3s forwards; /* Adjust the duration as needed */
}

@keyframes slideInAnimation {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0); /* End at the original position */
  }
}

/* Animate.css classes for animation */
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

  